// reWriteMessage.js
import Vue from 'vue'
import Main from 'element-ui/packages/message/src/main.vue'
import PopupManager from 'element-ui/lib/utils/popup/popup-manager'
import { isVNode } from 'element-ui/src/utils/vdom'
const MessageConstructor = Vue.extend(Main)

let instance
const instances = []
let seed = 1

const Message = function (options) {
  if (Vue.prototype.$isServer) return
  options = options || {}
  if (typeof options === 'string') {
    options = {
      message: options
    }
  }
  const userOnClose = options.onClose
  const id = 'message_' + seed++

  options.onClose = function () {
    Message.close(id, userOnClose)
  }
  instance = new MessageConstructor({
    data: options
  })
  instance.id = id
  if (isVNode(instance.message)) {
    instance.$slots.default = [instance.message]
    instance.message = null
  }
  instance.$mount()
  document.body.appendChild(instance.$el)
  const verticalOffset = options.offset || 20
  // 注释偏移叠加的代码
  // instances.forEach(item => {
  //   verticalOffset += item.$el.offsetHeight + 16
  // })
  instance.verticalOffset = verticalOffset
  instance.visible = true
  instance.$el.style.zIndex = PopupManager.nextZIndex()
  instances.push(instance)
  return instance
};

['success', 'warning', 'info', 'error'].forEach(type => {
  Message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return Message(options)
  }
})

Message.close = function (id, userOnClose) {
  // 注释掉关闭通知时,把后面的通知往上移动的代码
  const len = instances.length
  // let index = -1
  // let removedHeight
  for (let i = 0; i < len; i++) {
    if (id === instances[i].id) {
      // removedHeight = instances[i].$el.offsetHeight
      // index = i
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i])
      }
      // 被挡住的通知消息被遮挡，退出时不显示动画
      if (instances.length > 1) {
        instances[i].$el.style.display = 'none'
      }
      instances.splice(i, 1)
      break
    }
  }
  // if (len <= 1 || index === -1 || index > instances.length - 1) return
  // for (let i = index; i < len - 1; i++) {
  //   const dom = instances[i].$el
  //   dom.style.top =
  //     parseInt(dom.style.top, 10) - removedHeight - 16 + 'px'
  // }
}

Message.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

export default Message
