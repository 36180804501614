<template>
  <div id="patrol">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>  
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/patrol' }">巡视点管理</el-breadcrumb-item>
          <el-breadcrumb-item>新增巡视点</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="巡视点名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入巡视点名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属部门" prop="departmentId">
                <el-select v-model="form.departmentId" clearable placeholder="请选择所属部门" style="width: 335px">
                  <el-option v-for="item in bumen" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设施位置" prop="facilityId">
                <el-select v-model="form.facilityId" clearable placeholder="请选择设施位置" style="width: 335px">
                  <el-option v-for="item in sheshi" :label="item.name" :value="item.id" :key="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="描述说明">
                <el-input v-model="form.description" placeholder="请输入描述说明"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">设备信息</div>
          <div style="padding-left: 35px">
            <el-button @click="addDevice" type="text">增加巡视点设备</el-button>
          </div>
          <div v-for="(val, key) in aliasObj" :key="key">
            <div class="device-model" v-for="(item, index) in val.modelArr" :key="index">
              <div class="title-close" :style="!item.dShows?'margin:0;border:0;':''">
                <span>
                  <span class="iconfont" :class="item.dShows?'el-icon-arrow-up':'el-icon-arrow-down'" style="font-size: 20px;color: #409EFF;" @click="toggleDevice(item)"></span> 
                  {{ val.archivesName }} - {{ index+1 }}
                </span>
                <span @click="closeDevice(val.modelArr, index)">删除</span>
              </div>
              <el-collapse-transition>
                <div v-if="item.dShows">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="设备别名">
                        <el-input v-model="item.alias"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="设备识别号">
                        <el-input v-model="item.ein"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="设备名称">
                        <el-input v-model="val.archivesName" disabled></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="规格参数">
                        <el-input v-model="val.specification" disabled></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="所属部门">
                        <el-input v-model="val.departmentName" disabled></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="设备类型">
                        <el-input v-model="val.typeName" disabled></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="投入使用时间" label-width="106px">
                        <el-date-picker
                          style="width: 335px"
                          clearable
                          v-model="item.applyTime"
                          value-format="timestamp"
                          type="date"
                          placeholder="选择日期">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- 巡检模块 -->
                  <div class="patrol-con">
                    <div>
                      <div class="form-title patrol-title">
                        <div style="display: flex;">
                          <span class="iconfont icon-xunjian" style="font-size: 20px;margin-right: 5px;"></span>
                          <span>巡检内容</span>
                        </div>
                        <div>
                          <!-- 是否显示巡检项：<el-switch v-model="item.patrol"></el-switch> -->
                        </div>
                      </div>
                      <div v-show="item.patrol">
                        <div class="con-item" v-for="(pItem, pIndex) in item.patrolContents" :key="pIndex">
                          {{pIndex+1}}.检查内容
                          <el-input v-model="pItem.checkContent" style="width:50%;margin: 0 10px;"></el-input>
                          <span @click="deleteDate(item.patrolContents, pIndex)" v-if="pIndex != 0" style="cursor: pointer;color: red;">删除</span>
                        </div>
                        <div class="add-checkContent">
                          <span @click="addCheckContent(item.patrolContents, 0)">自定义添加</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 点检模块 -->
                  <div class="patrol-con">
                    <div>
                      <div class="form-title patrol-title">
                        <div style="display: flex;">
                          <span class="iconfont icon-tubiaozhizuomoban-133" style="font-size: 20px;margin-right: 5px;"></span>
                          <span>点检内容</span>
                        </div>
                        <div>
                          是否显示点检项：<el-switch v-model="item.check"></el-switch>
                        </div>
                      </div>
                      <div v-show="item.check">
                        <div class="con-item" v-for="(cItem, cIndex) in item.checkContents" :key="cIndex">
                          {{cIndex+1}}.检查内容
                          <el-input v-model="cItem.checkContent" style="width:50%;margin: 0 10px;"></el-input>
                          <span @click="deleteDate(item.checkContents, cIndex)" v-if="cIndex != 0" style="cursor: pointer;color: red;">删除</span>
                        </div>
                        <div class="add-checkContent">
                          <span @click="addCheckContent(item.checkContents, 1)">自定义添加</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 润滑模块 -->
                  <div class="patrol-con lube-con">
                    <div>
                      <div class="form-title lube-title">
                        <div style="display: flex;">
                          <span class="iconfont icon-zhouchengrunhuayou" style="font-size: 20px;margin-right: 5px;"></span>
                          <span>润滑内容</span>
                        </div>
                        <div>
                          是否显示润滑项：<el-switch v-model="item.lube"></el-switch>
                        </div>
                      </div>
                      <div v-show="item.lube" v-for="(luItem, luIndex) in item.lubeCheckContents" :key="luIndex">
                        <div class="item-title lube-title">
                          <span>润滑部位{{luIndex+1}}</span>
                          <span @click="deleteLube(item.lubeCheckContents, luIndex)" v-if="luIndex != 0" style="cursor: pointer;color: red;">删除</span>
                          <span class="lube-add" @click="addLube(item.lubeCheckContents)" v-else>添加</span>
                        </div>
                        <div class="item-con">
                          <el-row :gutter="50">
                            <el-col :span="8">
                              <el-form-item label="部位名称">
                              <el-input v-model="luItem.position"></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="润滑点数">
                              <el-input v-model="luItem.points"></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="20">
                            <el-col :span="6">
                              <el-form-item label="润滑油型号">
                                <button v-if="!luItem.brand" class="lube-btn" @click.prevent="handleModel(key, val.modelArr, index, luItem, luIndex)">请选择</button>
                                <span v-else class="close-lube-btn">
                                  {{luItem.model}}
                                  <span class="el-icon-error close-lube" @click="clearLubeOil(key, val.modelArr, index, luItem, luIndex)"></span>
                                </span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item label="润滑油品牌">
                                <el-input v-model="luItem.brand" disabled></el-input>
                              </el-form-item>  
                            </el-col>
                            <el-col :span="6">
                              <el-form-item label="润滑油分类">
                                <el-input v-model="luItem.classification" disabled></el-input>
                              </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item label="润滑油等级">
                                <el-input v-model="luItem.level" disabled></el-input>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <el-row :gutter="50">
                            <el-col :span="8">
                              <el-form-item label="换底油用量" style="position: relative;">
                              <el-input v-model="luItem.replace"></el-input>
                              <span style="position: absolute;right: -20px;top: 0;">kg</span>
                              </el-form-item>
                            </el-col>
                            <el-col :span="8">
                              <el-form-item label="补加油用量" style="position: relative;">
                              <el-input v-model="luItem.refuel"></el-input>
                              <span style="position: absolute;right: -20px;top: 0;">kg</span>
                              </el-form-item>
                            </el-col>
                          </el-row>
                          <div style="margin-left: 20px;font-weight: bold;">润滑方法</div>
                          <div class="con-item" v-for="(lueItem, lueIndex) in luItem.lubeCheckMethods" :key="lueIndex">
                            {{lueIndex+1}}.方法内容
                            <el-input v-model="lueItem.method" style="width:50%;margin: 0 10px;"></el-input>
                            <span @click="deleteLubeMethod(luItem.lubeCheckMethods, lueIndex)" v-if="lueIndex != 0" style="cursor: pointer;color: red;">删除</span>
                          </div>
                          <div class="add-checkContent">
                            <span @click="addLubeMethod(luItem.lubeCheckMethods)">添加内容</span>
                          </div>
                        </div>
                        <el-drawer
                          title="添加润滑油"
                          :with-header="false"
                          :visible.sync="luItem.drawerLube"
                          direction="rtl"
                          size="800px">
                          <div class="drawer-title" style="font-size:16px;">添加润滑油</div>
                          <div class="drawer-content">
                            <el-form :inline="true" :model="formLube" class="demo-form-inline">
                              <el-form-item label="品牌">
                                <el-select
                                  size="small"
                                  v-model="formLube.brandId"
                                  clearable
                                  placeholder="请选择品牌"
                                  style="width: 160px">
                                  <el-option
                                    v-for="item in brand"
                                    :label="item.brand"
                                    :value="item.id"
                                    :key="item.id"></el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item label="分类">
                                <el-select
                                  size="small"
                                  v-model="formLube.classificationId"
                                  clearable
                                  placeholder="请选择分类"
                                  style="width: 160px">
                                  <el-option
                                    v-for="item in classification"
                                    :label="item.classification"
                                    :value="item.id"
                                    :key="item.id"></el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item label="等级">
                                <el-select
                                  size="small"
                                  v-model="formLube.levelId"
                                  clearable
                                  placeholder="请选择等级"
                                  style="width: 160px">
                                  <el-option
                                    v-for="item in level"
                                    :label="item.level"
                                    :value="item.id"
                                    :key="item.id"></el-option>
                                </el-select>
                              </el-form-item>
                              <el-form-item>
                                <el-button size="small" type="primary" @click="onLubeSubmit">查询</el-button>
                              </el-form-item>
                            </el-form>
                            <el-table :data="tableLubeData" height="700">
                              <el-table-column label="勾选" width="70">
                                <template slot-scope="scope">
                                  <el-radio-group v-model="deviceRadio" @change="checkLubeChange(scope.row)">
                                    <el-radio :label="scope.row.id">{{''}}</el-radio>
                                  </el-radio-group>
                                </template>
                              </el-table-column>
                              <el-table-column prop="code" label="编码"></el-table-column>
                              <el-table-column prop="model" label="型号"></el-table-column>
                              <el-table-column prop="specification" label="规格参数"></el-table-column>
                              <el-table-column prop="brand" label="品牌"></el-table-column>
                              <el-table-column prop="classification" label="分类"></el-table-column>
                              <el-table-column prop="level" label="等级"></el-table-column>
                            </el-table>
                          </div>
                          <div class="drawer-bottom">
                            <el-button type="primary" size="small" @click="luItem.drawerLube=false">取消</el-button>
                            <el-button
                              size="small"
                              type="primary"
                              @click="drawerLubeOK(key, val.modelArr, index, luItem, luIndex)"
                              class="drawer-bottom-ok">确 定</el-button>
                          </div>
                        </el-drawer>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
          <div class="bottom-btn">
            <div style="text-align: right;margin-right: 20px;">
              <el-button type="primary" size="small" @click="$router.push('/patrol')">取消</el-button>
              <el-button type="primary" @click="formSubmit" size="small">提交</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <el-drawer
        title="添加设备"
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="800px">
        <div class="drawer-title">添加设备</div>
        <div class="drawer-content">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="类型">
              <el-select
                size="small"
                v-model="formInline.deviceTypeId"
                clearable
                placeholder="请选择类型"
                style="width: 160px">
                <el-option
                  v-for="item in leixin"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属部门">
              <el-select
                size="small"
                v-model="formInline.departmentId"
                clearable
                placeholder="请选择所属部门"
                style="width: 160px">
                <el-option
                  v-for="item in bumen"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负责人">
              <el-select
                size="small"
                v-model="formInline.staffId"
                clearable
                placeholder="请选择负责人"
                style="width: 160px">
                <el-option
                  v-for="item in yuangong"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="tableDataCp" height="700">
            <el-table-column label="勾选" width="70">
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="materialsCode" label="编码"></el-table-column>
            <el-table-column prop="deviceTypeName" label="分类"></el-table-column>
            <el-table-column prop="departmentName" label="所属部门"></el-table-column>
            <el-table-column prop="staffName" label="负责人"></el-table-column>
            <el-table-column prop="count" label="数量"></el-table-column>
            <el-table-column prop="useCount" label="用量"></el-table-column>
            <el-table-column label="选择数量" width="90">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.num"
                  :controls="false"
                  :min="0"
                  :max="scope.row.count - scope.row.useCount"
                  size="small"
                  :disabled="scope.row.checked"></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="drawer-bottom">
          <el-button type="primary" size="small" @click="drawer=false">取消</el-button>
          <el-button
            size="small"
            type="primary"
            @click="drawerOK"
            class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import wMessage from '../../js/reWriteMessage.js'
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Vue from 'vue'

Vue.component(CollapseTransition.name, CollapseTransition)
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        departmentId:[{ required: true, message: '必填项', trigger: 'change' }],
        facilityId:[{ required: true, message: '必填项', trigger: 'change' }],
        alias:[{ required: true, message: '必填项', trigger: 'blur' }],
        ein:[{ required: true, message: '必填项', trigger: 'blur' }],
        applyTime:[{type: 'date', required: true, message: '必填项', trigger: 'change' }],
      },
      
      drawer: false, // 设备信息
      aliasObj: {}, 
      tableData: [],
      tableDataCp: [],
      
      formInline: {}, // 添加设备
      bumen: [],
      leixin: [],
      sheshi: [],
      yuangong: [],
      tableSelectList: [],
      
      formLube: {}, // 添加润滑油
      tableLubeData: [],
      deviceRadio: '',
      selectLube: {},
      model: [],
      brand: [],
      classification: [],
      level: []
    };
  },
  methods: {
    // 折叠设备
    toggleDevice(item) {
      item.dShows = !item.dShows
    },
    // 点击选择润滑油
    handleModel(key, dArr, dIndex, luItem, luIndex) {
      for(let i in this.aliasObj) {
        if(i == key) {
          dArr.forEach((v, k)=>{
            if(k == dIndex) {
              v.lubeCheckContents.forEach((it, ind)=>{
                if(ind == luIndex) {
                  luItem.drawerLube = true
                }
              })
            }
          })
        }
      }
    },
    // 清除润滑油
    clearLubeOil(key, dArr, dIndex, luItem, luIndex) {
      for(let i in this.aliasObj) {
        if(i == key) {
          dArr.forEach((v, k)=>{
            if(k == dIndex) {
              v.lubeCheckContents.forEach((it, ind)=>{
                if(ind == luIndex) {
                  luItem.brand = ''
                  luItem.classification = '' 
                  luItem.level = '' 
                  this.$forceUpdate()
                }
              })
            }
          })
        }
      }
    },
    // 勾选润滑油
    checkLubeChange(row) {
      this.selectLube = row
    },
    // 润滑油勾选后点击确定
    drawerLubeOK(key, dArr, dIndex, luItem, luIndex) {
      for(let i in this.aliasObj) {
        if(i == key) {
          dArr.forEach((v, k)=>{
            if(k == dIndex) {
              v.lubeCheckContents.forEach((it, ind)=>{
                if(ind == luIndex) {
                  luItem.lubeArchivesId = this.selectLube.id
                  luItem.model = this.selectLube.model
                  luItem.brand = this.selectLube.brand 
                  luItem.classification = this.selectLube.classification 
                  luItem.level = this.selectLube.level 
                  luItem.drawerLube = false
                  this.deviceRadio = ''
                }
              })
            }
          })
        }
      }
    },
    // 获取设备列表
    loadListData() {
      this.$ajax.post("deviceChecklist", {
        departmentId: this.formInline.departmentId,
        deviceTypeId: this.formInline.deviceTypeId,
        staffId: this.formInline.staffId,
      }).then((res) => {
        this.tableData = res.data;
        this.tableDataCp = []
        this.tableData.forEach(item => {
          if(item.count == item.useCount) {
            return
          }
          let d = JSON.parse(JSON.stringify(item));
          d.num = 1
          d.checked = false
          this.tableDataCp.push(d)
        })
      });
    },
    load_bumen() {
      this.$ajax.get("departmentSecondLevel").then((res) => {
        this.bumen = res.data;
      })
    },
    load_leixing() {
      this.$ajax.get("typeQueryAll").then((res) => {
        this.leixin = res.data;
      });
    },
    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },
    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },
    load_sheshi() {
      this.$ajax.get("facilityQueryAll").then((res) => {
        this.sheshi = res.data;
      });
    },
    load_Model() {
      this.$ajax.post("lubeManager", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.model = res.data;
      });
    },
    load_brand() {
      this.$ajax.post("lubeBrand", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.brand = res.data
      });
    },
    load_classification() {
      this.$ajax.post("lubeClassification", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.classification = res.data
      })
    },
    load_level() {
      this.$ajax.post("lubeLevel", {
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.level = res.data
      })
    },
    addDevice() {
      this.drawer = true;
      this.tableSelectList = [];
      
      this.tableDataCp = []
      this.tableData.forEach(item => {
        if(item.count == item.useCount) {
          return
        }
        let d = JSON.parse(JSON.stringify(item));
        d.num = 1
        d.checked = false
        this.tableDataCp.push(d)
      })
    },
    closeDevice(arr, index) {
      arr.splice(index, 1)
    },
    onLubeSubmit() {
      this.loadTableData()
    },
    onSubmit() {
      this.loadListData();
    },
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    drawerOK() {
      this.tableSelectList.forEach(item => {
        this.$ajax.post("deviceDetail", { id: item.id }).then((res) => {
          res.data.num = item.num;
          res.data.modelArr = [];
          
          for(let i=0; i < res.data.num; i++){
            res.data.modelArr[i] = {
              dShows: true,
              patrol: true,
              check: false,
              lube: false,
              alias: '',
              ein: '',
              applyTime: '',
              patrolContents: [{
                checkContent: '',
                taskType: 0
              }],
              checkContents: [{
                checkContent: '',
                taskType: 1
              }],
              lubeCheckContents: [{
                drawerLube: false,
                lubeArchivesId: '',
                lubeCheckMethods: [
                  {
                    method: ''
                  }
                ],
                points: '',
                position: '',
                refuel: '',
                replace: ''
              }]
            }
          }
          this.$set(this.aliasObj, item.id, res.data);
        });
      })
      this.drawer = false;
    },
    // 添加巡检检查模块
    addCheckContent(arr, num) {
     arr.push({
       checkContent: '',
       taskType: num
     })
    },
    deleteDate(arr, i) {
      arr.splice(i, 1)
    },
    // 添加润滑模块
    addLube(arr) {
      arr.push({
        drawerLube: false,
        lubeArchivesId: '',
        lubeCheckMethods: [
          {
            method: ''
          }
        ],
        patrolDeviceId: '',
        points: '',
        position: '',
        refuel: '',
        replace: ''
      })
    },
    deleteLube(arr, i) {
      arr.splice(i, 1)
    },
    // 添加润滑方法模块
    addLubeMethod(arr) {
      arr.push({
        method: ''
      })
    },
    deleteLubeMethod(arr, i) {
      arr.splice(i, 1)
    },
    formSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let patrolDevices = [];
          for (let i in this.aliasObj) {
            this.aliasObj[i].modelArr.forEach((val, key) => {
              let arr = []
              val.patrolContents.forEach((i,d)=>{
                arr.push({
                  checkContent: i.checkContent,
                  taskType: i.taskType
                })
              })
              val.checkContents.forEach((i,d)=>{
                if(val.check) {
                  arr.push({
                    checkContent: i.checkContent,
                    taskType: i.taskType
                  })
                }
              })
              let lubeArr = []
              val.lubeCheckContents.forEach((i,d)=>{
                lubeArr.push({
                  lubeArchivesId: i.lubeArchivesId,
                  lubeCheckMethods: i.lubeCheckMethods,
                  points: i.points,
                  position: i.position,
                  refuel: i.refuel,
                  replace: i.replace
                })
              })
              if(!val.lube) {
                lubeArr = []
              }
              patrolDevices.push({
                alias: val.alias,
                applyTime: val.applyTime,
                archivesId: i,
                checkContents: arr,
                ein: val.ein,
                lubeCheckContents: val.lube ? lubeArr : null
              })
            })
          }
          let form = {
            departmentId: this.form.departmentId,
            description: this.form.description,
            facilityId: this.form.facilityId,
            name: this.form.name,
            patrolDevices: patrolDevices,
          };
          
          if (patrolDevices.length == 0) {
            this.$message.error("设备信息不能为空");
            return
          }
          // 润滑内容按钮打开必填验证
          // patrolDevices.forEach((val, key)=>{
          //   if(!val.alias) return wMessage.error("设备别名不能为空")
          //   if(!val.ein) return wMessage.error("设备识别号不能为空")
          //   if(!val.applyTime) return wMessage.error("请选择设备投入时间")
          //   if(val.lubeCheckContents) {
          //     val.lubeCheckContents.forEach((item, index)=>{
          //       if(!item.position) return wMessage.error("润滑部位不能为空")
          //       if(!item.points) return wMessage.error("润滑点数不能为空")
          //       if(!item.lubeArchivesId) return wMessage.error("润滑型号不能为空")
          //       if(!item.replace) return wMessage.error("换底油用量不能为空")
          //       if(!item.refuel) return wMessage.error("补加油用量不能为空")
          //       item.lubeCheckMethods.forEach(i=>{
          //         if(!i.method) return wMessage.error("润滑方法不能为空")
          //       })
          //     })
          //   }
          // })

          for(let i=0;i<patrolDevices.length;i++){
            if(!patrolDevices[i].alias) return wMessage.error("设备别名不能为空")
            if(!patrolDevices[i].ein) return wMessage.error("设备识别号不能为空")
            if(!patrolDevices[i].applyTime) return wMessage.error("请选择设备投入时间")

            if(patrolDevices[i].checkContents) {
              for(let j=0;j<patrolDevices[i].checkContents.length;j++){
                if(patrolDevices[i].checkContents[j].taskType == 0){
                  if(!patrolDevices[i].checkContents[j].checkContent) return wMessage.error("巡检内容不能为空")
                }else {
                  if(!patrolDevices[i].checkContents[j].checkContent) return wMessage.error("点检内容不能为空")
                }
              }
            }

            if(patrolDevices[i].lubeCheckContents) {
              for(let k=0;k<patrolDevices[i].lubeCheckContents.length;k++){
                if(!patrolDevices[i].lubeCheckContents[k].position) return wMessage.error("润滑部位不能为空")
                if(!patrolDevices[i].lubeCheckContents[k].points) return wMessage.error("润滑点数不能为空")
                if(!patrolDevices[i].lubeCheckContents[k].lubeArchivesId) return wMessage.error("润滑型号不能为空")
                if(!patrolDevices[i].lubeCheckContents[k].replace) return wMessage.error("换底油用量不能为空")
                if(!patrolDevices[i].lubeCheckContents[k].refuel) return wMessage.error("补加油用量不能为空")
                for(let l=0;l<patrolDevices[i].lubeCheckContents[k].lubeCheckMethods.length;l++){
                  if(!patrolDevices[i].lubeCheckContents[k].lubeCheckMethods[l].method) return wMessage.error("润滑方法不能为空")
                }
              }
            }
          }

          for(let i=0; i<patrolDevices.length; i++){
            if(!patrolDevices[i].alias || !patrolDevices[i].ein || !patrolDevices[i].applyTime) {
              return
            }
            if(patrolDevices[i].lubeCheckContents) {
              for(let j=0; j<patrolDevices[i].lubeCheckContents.length; j++) {
                if(!patrolDevices[i].lubeCheckContents[j].position || !patrolDevices[i].lubeCheckContents[j].points || !patrolDevices[i].lubeCheckContents[j].lubeArchivesId || !patrolDevices[i].lubeCheckContents[j].replace || !patrolDevices[i].lubeCheckContents[j].refuel) {
                  return
                }
                for(let k=0; k<patrolDevices[i].lubeCheckContents[j].lubeCheckMethods.length; k++) {
                  if(!patrolDevices[i].lubeCheckContents[j].lubeCheckMethods[k].method) {
                    return
                  }
                }
              }
            }
          }

          this.loading = true
          this.$ajax.post("patrolCreate", form).then((res) => {
            this.$message.success("成功");
            this.$router.push("/patrol");
            this.loading = false
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    // 获取润滑油列表
    loadTableData() {
      this.$ajax.post("lubeManager", {
        brandId: this.formLube.brandId,
        classificationId: this.formLube.classificationId,
        levelId: this.formLube.levelId,
        pageNum: 0,
        pageSize: 0,
      }).then((res) => {
        this.tableLubeData = res.data;
      });
    },
  },
  mounted() {
    this.load_Model();
    this.load_brand();
    this.load_classification();
    this.load_level();
    this.load_bumen();
    this.load_leixing();
    this.load_yuangong();
    this.load_sheshi();
    this.loadListData();
    this.loadTableData();
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.demo-form-inline {
  padding-top: 20px;
}
.drawer-content {
  padding: 0 20px;
}
.device-model {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 10px 20px;
  .title-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    span:nth-last-child(1) {
      color: red;
      cursor: pointer;
      font-weight: 400;
    }
  }
}
.form-title {
  font-weight: bold;
}
.patrol-con {
  font-size: 14px;
  .patrol-title {
    border-left: 0;
    border-top: 1px solid #eee;
    padding: 30px 0 0;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
  }
  .con-item {
    display: flex;
    align-items: center;
    margin: 10px 20px;
  }
}
.lube-con {
  .lube-title {
    border-left: 0;
    border-top: 1px solid #eee;
    padding: 30px 0 0;
    margin: 10px 0;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    .lube-add {
      margin-left: 30px;
      color: #66b1ff;
      cursor: pointer;
    }
  }
  .item-title {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    background-color: #f2f2f2;
    padding: 0 15px;
  }
  .item-con {
    margin: 20px 0;
  }
}
.drawer-bottom {
  position: static;
  z-index: 1;
  text-align: right;
  padding: 10px 20px 10px 0;
}
.lube-btn {
  border: none;
  outline: none;
  width: 100%;
  color: #FFF;
  cursor: pointer;
  line-height: 40px;
  border-radius: 4px;
  background: #409EFF;
  border-color: #66b1ff;
}
.lube-btn:hover{
  background: #66b1ff;
  border-color: #66b1ff;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 1px 10px;
  color: #409EFF;
  line-height: 40px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  .close-lube {
    cursor: pointer;
  }
}
</style>
<style lang="less">
#patrol {
  .el-input-number--small {
    width: 60px;
  }
  .el-input-number.is-without-controls .el-input__inner {
    padding: 0 4px;
  }
}
.el-drawer.rtl {
  overflow: scroll;
}
</style>